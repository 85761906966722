var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canViewHorizontalNavMenuLink(_vm.item) && _vm.isEnabled(_vm.item) && _vm.storeIsEnable(_vm.item) && _vm.isZatca(_vm.item) && _vm.isTempFinancial(_vm.item) && _vm.deservedFeeAppearanceHandle(_vm.item))?_c('li',{staticClass:"nav-item",class:{
    active: _vm.isActive,
    disabled: _vm.item.disabled,
    'd-flex': !_vm.item.icon,
    'pl-25': !_vm.item.icon,
  }},[(_vm.item.isFav === true)?_c('feather-icon',{staticClass:"cursor-pointer fill-current",class:_vm.item.icon ? 'd-none' : 'd-block mt-1 ml-1 text-warning',attrs:{"icon":"StarIcon","size":"16"},on:{"click":function () {
        _vm.makeFav(_vm.item);
      }}}):_vm._e(),(_vm.item.isFav === false)?_c('feather-icon',{staticClass:"cursor-pointer",class:_vm.item.icon ? 'd-none' : 'd-block mt-1 ml-1 text-warning',attrs:{"icon":"StarIcon","size":"16"},on:{"click":function () {
        _vm.makeFav(_vm.item);
      }}}):_vm._e(),_c('b-link',_vm._b({staticClass:"dropdown-item d-flex align-items-center"},'b-link',_vm.linkProps,false),[(_vm.item.isFav === true || _vm.item.isFav === false )?_c('feather-icon',{class:_vm.item.icon ? 'd-block' : 'd-none',attrs:{"icon":_vm.item.icon || ''}}):_vm._e(),_c('span',{staticClass:"menu-title text-truncate"},[_vm._v(_vm._s(_vm.t(_vm.item.title)))]),(_vm.item.tag)?_c('b-badge',{staticClass:"mr-1 ml-auto",attrs:{"pill":"","variant":_vm.item.tagVariant || 'primary'}},[_vm._v(" "+_vm._s(_vm.item.tag)+" ")]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }